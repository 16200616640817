import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IntegrationItem } from "../../../models/integration-item.model";

export interface WorkflowState {
  integrationItems: IntegrationItem[];
  activeIntegrations: IntegrationItem[];
  lastLoadDate: string;
}

// Update initial state
export const initialState: WorkflowState = {
  integrationItems: [],
  activeIntegrations: [],
  lastLoadDate: new Date().toISOString(),
};

export const workflowSlice = createSlice({
  name: "workflow",
  initialState,
  reducers: {
    // Add a new integration item
    addIntegrationItem: (state, action: PayloadAction<IntegrationItem>) => {
      state.integrationItems.push(action.payload);
    },

    // Update an existing integration item
    updateIntegrationItem: (state, action: PayloadAction<IntegrationItem>) => {
      const index = state.integrationItems.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index > -1) {
        state.integrationItems[index] = action.payload;
      }
    },

    // Remove an integration item
    removeIntegrationItem: (state, action: PayloadAction<string>) => {
      state.integrationItems = state.integrationItems.filter(
        (item) => item.id !== action.payload
      );
    },

    // Set all integration items
    setIntegrationItems: (state, action: PayloadAction<IntegrationItem[]>) => {
      state.integrationItems = action.payload;
      state.lastLoadDate = new Date().toISOString();
    },

    setActiveIntegrations: (
      state,
      action: PayloadAction<IntegrationItem[]>
    ) => {
      state.activeIntegrations = action.payload;
      state.lastLoadDate = new Date().toISOString();
    },

    //Update single integration last run
    updateIntegrationLastRun: (
      state,
      action: PayloadAction<{ id: string }>
    ) => {
      const index = state.activeIntegrations.findIndex(
        (item) => +item.id === +action.payload.id
      );
      if (index > -1) {
        state.activeIntegrations[index].lastRunDate = new Date().toISOString();
        state.activeIntegrations[index].conclusion = undefined;
      }
    },
  },
});

// Export actions
export const {
  addIntegrationItem,
  updateIntegrationItem,
  removeIntegrationItem,
  setIntegrationItems,
  setActiveIntegrations,
  updateIntegrationLastRun,
} = workflowSlice.actions;

export default workflowSlice.reducer;
