"use client";

import { useEffect, useState } from "react";
import Layout from "../../components/app/Layout";
import BaseContainer from "../../components/form/v2/BaseContainer";
import { Loading } from "../../components/shared/Loading";
import { StatusTag } from "../../components/shared/StatusTag";
import { IntegrationItem } from "../../models/integration-item.model";
import { integrationService, IntegrationService } from "../../service/integration.service";
import { formatDate, getFirstDayOfMonth } from "../../utils/date.util";
import { formatSeconds } from "../../utils/number.util";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../third-party/shadcn/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../third-party/shadcn/table";
import { RootState, store } from "../../store";
import {
  setActiveIntegrations,
  setIntegrationItems,
} from "../../store/slice/Workflows/workflow.slice";
import { useSelector } from "react-redux";
import { RunFrequency } from "../../components/dashboard/v2/RunFrequency";
import { IntegrationPieChart } from "../../components/dashboard/v2/IntegrationFailureRate";
import { RunTime } from "../../components/dashboard/v2/RunTimes";
import { ReloadIcon } from "@radix-ui/react-icons";
import { errorToast } from "../../constants/toast.constants";
import { useToast } from "@chakra-ui/react";
import { LinkIcon } from "../../components/shared/icons/icons";
import { Calendar1Icon } from "lucide-react";

export const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [monthRuns, setMonthRuns] = useState<IntegrationItem[]>([]);
  const [averageTime, setAverageTime] = useState(0);
  const [integrations, setIntegrations] = useState<IntegrationItem[]>([]);

  const toast = useToast();

  const integrationWorkflows = useSelector(
    (state: RootState) => state.workflows
  ) ?? { integrationItems: [], activeIntegrations: [] };

  const init = async () => {
    await setIntegrationData();
  };

  const setIntegrationData = async (force: boolean = false) => {
    let runs = integrationWorkflows.integrationItems;
    let activeIntegrations = integrationWorkflows.activeIntegrations;

    if (shouldLoadNewData() || force) {
      setLoading(true);

      try {
        runs = await integrationService.getWorkflowsWithRuns();
        activeIntegrations = await integrationService.getWorkflowsWithRuns({
          limit: 1,
        });

        store.dispatch(setIntegrationItems(runs));
        store.dispatch(setActiveIntegrations(activeIntegrations));
      } catch (error) {
        toast(errorToast("Failed to fetch integrations."));
      } finally {
        setLoading(false);
      }
    }

    const dateFiltered = runs.filter(
      (run) => new Date(run.lastRunDate) >= new Date(getFirstDayOfMonth())
    );

    const avgTime =
      dateFiltered.reduce((acc, cur) => acc + cur.duration, 0) /
      dateFiltered.length;

    setAverageTime(+avgTime.toFixed(2));
    setMonthRuns(dateFiltered);

    const { count } = await integrationService.getWorkflowCount();
    setCount(count);

    setIntegrations(activeIntegrations);
  };

  const shouldLoadNewData = () => {
    let runs = integrationWorkflows.integrationItems;
    let activeIntegrations = integrationWorkflows.activeIntegrations;

    if (!runs?.length || !activeIntegrations?.length) return true;

    const lastLoadDate = new Date(integrationWorkflows.lastLoadDate);
    const now = new Date();
    const diffInMs = now.getTime() - lastLoadDate.getTime();
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    return diffInDays >= 1 || lastLoadDate < new Date(getFirstDayOfMonth());
  };

  const lastRefreshedDate = integrationWorkflows.lastLoadDate;
  const formattedLastRefreshedDate = formatDate(lastRefreshedDate);

  useEffect(() => {
    init();
  }, []);

  return (
    <Layout>
      <Loading
        loading={loading}
        text="Fetching all your data - this may take a few moments."
      />

      <BaseContainer className="p-0 flex-grow rounded-lg min-h-[calc(100vh-104px)]">
        <div className="my-5">
          <div className="text-4xl font-bold">My Overview</div>
        </div>
        <div className="flex flex-row items-center p-1.5 mb-1.5 justify-between">
          <div className="flex flex-row items-center gap-2">
            <Calendar1Icon />
            <div className=" text-md font-medium w-fit">
              01 {new Date().toLocaleString("default", { month: "long" })} -
              Today
            </div>
          </div>
          <div className="flex flex-row gap-3 items-center text-sm text-gray-500">
            <div className="flex flex-row gap-2">
              <p>Last refreshed:</p>
              <p>{formattedLastRefreshedDate}</p>
            </div>
            <Card
              className="w-fit h-8 p-2 flex justify-center items-center rounded-md cursor-pointer gap-2"
              onClick={() => setIntegrationData(true)}
            >
              <ReloadIcon />
              <p className="text-sm">Refresh</p>
            </Card>
          </div>
        </div>
        <div className="flex flex-col gap-1.5">
          <div className="flex gap-1.5">
            <Card className="w-1/4 text-center">
              <CardHeader>
                <CardDescription>Active Integrations</CardDescription>
                <CardTitle className="text-4xl mt-5 mb-5">{count}</CardTitle>
              </CardHeader>
              <CardContent></CardContent>
            </Card>

            <Card className="w-1/4 text-center">
              <CardHeader>
                <CardDescription>Time to Execute</CardDescription>
                <CardTitle className="text-4xl mt-5 mb-5">
                  {formatSeconds(averageTime)}
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="text-xs text-muted-foreground">
                  average this month
                </div>
              </CardContent>
            </Card>

            <Card className="w-1/4 text-center">
              <CardHeader>
                <CardDescription>Total Failures (month)</CardDescription>
                <CardTitle className="text-4xl mt-5 mb-5">
                  {monthRuns.filter((x) => x.conclusion === "failure").length}
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="text-xs text-muted-foreground">this month</div>
              </CardContent>
            </Card>

            <Card className="w-1/4 text-center">
              <CardHeader>
                <CardDescription>Total Runs</CardDescription>
                <CardTitle className="text-4xl mt-5 mb-5">
                  {monthRuns.reduce((acc, cur) => acc + cur.attempt, 0)}
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="text-xs text-muted-foreground">this month</div>
              </CardContent>
            </Card>
          </div>

          <div className="flex gap-1.5">
            <Card className="w-3/4 h-[50vh] max-h-[500px]">
              {/* <RunFrequencyChart data={monthRuns} /> */}
              <RunFrequency data={monthRuns} />
            </Card>

            <Card className="w-1/4 h-[50vh] max-h-[500px]">
              {/* <FailureChart data={monthRuns} /> */}
              <IntegrationPieChart data={monthRuns} />
            </Card>
          </div>

          {/* old runTime chart */}
          {/* <Card className="w-full h-[60vh] max-h-[500px]">
            <CardHeader>
              <CardTitle>Run Times (avg)</CardTitle>
            </CardHeader>
            <CardContent className="h-[55vh] max-h-[450px] p-7.5">
              <RunTimeChart data={monthRuns} />
            </CardContent>
          </Card> */}

          <Card className="w-full max-h-[500px]">
            <RunTime data={monthRuns} />
          </Card>

          <Card className="w-full h-[50vh] overflow-auto">
            <CardHeader>
              <CardTitle>Active Integrations</CardTitle>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader className="sticky top-0 bg-white">
                  <TableRow>
                    <TableHead>Name</TableHead>
                    <TableHead>Description</TableHead>
                    <TableHead>Last Run</TableHead>
                    <TableHead>Last Run Status</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {integrations?.map(
                    (integration: IntegrationItem, i: number) => (
                      <TableRow key={i}>
                        <TableCell>{integration.friendlyName}</TableCell>
                        <TableCell>{integration.description}</TableCell>
                        <TableCell>
                          {formatDate(integration.lastRunDate)}
                        </TableCell>
                        <TableCell>
                          <StatusTag
                            type={integration.conclusion as any}
                            style={{ width: "120px" }}
                          >
                            {integration.conclusion}
                          </StatusTag>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </div>
      </BaseContainer>
    </Layout>
  );
};
