import { BaseMagiclinkService } from "./base-magiclink.service";

export class MagiclinkService extends BaseMagiclinkService {
  constructor() {
    super();
  }

  logout = async () => {
    await this.magic!.user.logout();
  };

  handleLoginWithEmail = async (
    email: string,
    password: string,
    isLogin: boolean = true
  ) => {
    await this.magic!.auth.loginWithMagicLink({
      email,
      showUI: true,
      redirectURI: new URL(
        `/auth/login/redirect/${email}/${password}`,
        window.location.origin
      ).href,
    }).addListener("done", (token: string | null) => {
      return token;
    });
  };

  getUser = async () => {
    return this.magic?.user?.getMetadata();
  };

  // Do not use unless you have to
  // Use Redux !!token.loginToken instead
  isAuthenticated = async () => {
    return new Promise<boolean>((resolve) => {
      this.magic?.user?.isLoggedIn().then((loggedIn) => {
        resolve(loggedIn);
      });
    });
  };

  getIdToken = async () => {
    return new Promise<string>((resolve) => {
      this.magic?.user?.getIdToken().then((token) => {
        resolve(token);
      });
    });
  };

  userEmail = async () => {
    return new Promise<string | null>((resolve) => {
      this.magic?.user
        ?.getMetadata()
        .then((metaData) => {
          resolve(metaData?.email);
        })
        .catch(async () => {
          await this.logout();
          resolve("");
        });
    });
  };
}

export const magiclinkService = new MagiclinkService();